import gql from "graphql-tag";
import { EX } from "pdfmake/build/pdfmake";
//Transponder clinet
export const ALL_TRANSPONDER_CLIENTS = gql`
  query TransponderClients {
    transponderClients {
      id
      organization
      org_email
      logo
      createdAt
      updatedAt
    }
  }
`;
//transponderRequest
export const TRANSPONDER_REQUESTS = gql`
  mutation CreateTransponderRequestTicket(
    $input: TransponderRequestTicketInput
  ) {
    createTransponderRequestTicket(input: $input) {
      id
    }
  }
`;
//Logout
export const LOGOUT_MUTATION = gql`
  mutation Logoutuser {
    logoutuser {
      userId
      token
      tokenExpiration
    }
  }
`;
//TURNOVER
export const TURNOVER_QUERY = gql`
  query TurnOverTickets($limit: Int!, $offset: Int!, $filter: ticketFilter) {
    turnOverTickets(limit: $limit, offset: $offset, filter: $filter) {
      data {
        priority {
          priority_name
          sla
          id
        }
        id
        assigned {
          first_name
          last_name
        }
        snaps

        assigned_date
        category {
          category_name
        }
        department {
          department_name
        }
        ticket_description
        ticket_number
        ticket_status {
          status_name
        }
        title
      }
      count
    }
  }
`;
//ADDONS
//TICKET CATEGORY
export const TICKETS_CATEGORY_QUERY = gql`
  query TicketCategories($limit: Int!, $offset: Int!) {
    ticketCategories(limit: $limit, offset: $offset) {
      category_name
      id
    }
  }
`;
export const EDIT_TICKET_CATEGORY_MUTATION = gql`
  mutation Mutation($categoryId: ID!, $input: InputTicketCategory) {
    updateTicketCategory(categoryId: $categoryId, input: $input) {
      category_name
    }
  }
`;
export const ADD_TICKET_CATEGORY_MUTATION = gql`
  mutation Mutation($input: InputTicketCategory) {
    createTicketCategory(input: $input) {
      category_name
    }
  }
`;
export const DELETE_TICKET_CATEGORY_MUTATION = gql`
  mutation Mutation($categoryId: ID!) {
    deleteTicketCategory(categoryId: $categoryId) {
      category_name
    }
  }
`;
//INTERNAL DEPARTMENTS
export const INTERNAL_DEPARTMENTS_QUERY = gql`
  query InternalDepartments($limit: Int!, $offset: Int!) {
    internalDepartments(limit: $limit, offset: $offset) {
      id
      department_name
      description
      createdAt
      updatedAt
    }
  }
`;
export const ADD_INTERNAL_DEPARTMENT_MUTATION = gql`
  mutation Mutation($input: InternalDepartmentInput!) {
    createInternalDepartment(input: $input) {
      department_name
      description
    }
  }
`;
export const DELETE_INTERNAL_DEPARTMENT_MUTATION = gql`
  mutation Mutation($deleteInternalDepartmentId: ID!) {
    deleteInternalDepartment(id: $deleteInternalDepartmentId) {
      department_name
      description
      id
    }
  }
`;
export const EDIT_INTERNAL_DEPARTMENT_MUTATION = gql`
  mutation UpdateInternalDepartment(
    $updateInternalDepartmentId: ID!
    $input: InternalDepartmentInput
  ) {
    updateInternalDepartment(id: $updateInternalDepartmentId, input: $input) {
      department_name
      description
      id
    }
  }
`;
//PRIORITY
export const PRIORITY_QUERY = gql`
  query TicketPriorities($limit: Int!, $offset: Int!) {
    ticketPriorities(limit: $limit, offset: $offset) {
      priority_name
      sla
      id
    }
  }
`;
export const ADD_PRIORITY_MUTATION = gql`
  mutation CreateTicketPriority($input: TicketPriorityInput) {
    createTicketPriority(input: $input) {
      sla
      priority_name
      id
    }
  }
`;
export const DELETE_PRIORITY_MUTATION = gql`
  mutation DeleteTicketPriority($priorityId: ID!) {
    deleteTicketPriority(priorityId: $priorityId) {
      sla
      priority_name
      id
    }
  }
`;
export const EDIT_PRIORITY_MUTATION = gql`
  mutation UpdateTicketPriority($priorityId: ID!, $input: TicketPriorityInput) {
    updateTicketPriority(priorityId: $priorityId, input: $input) {
      sla
      priority_name
    }
  }
`;
//STATUS
export const STATUS_QUERY = gql`
  query TicketStatuses($offset: Int, $limit: Int) {
    ticketStatuses(offset: $offset, limit: $limit) {
      status_name
      id
    }
  }
`;
export const ADD_STATUS_MUTATION = gql`
  mutation CreateTicketStatus($input: TicketStatusInput) {
    createTicketStatus(input: $input) {
      status_name
      id
    }
  }
`;
export const DELETE_STATUS_MUTATION = gql`
  mutation DeleteTicketStatus($ticketStatusId: ID!) {
    deleteTicketStatus(ticketStatusId: $ticketStatusId) {
      status_name
      id
    }
  }
`;
export const EDIT_STATUS_MUTATION = gql`
  mutation UpdateTicketStatus($ticketStatusId: ID!, $input: TicketStatusInput) {
    updateTicketStatus(ticketStatusId: $ticketStatusId, input: $input) {
      status_name
      id
    }
  }
`;
//task
//marktask
export const MARK_TASK = gql`
  mutation MarkTaskAsComplete($taskId: ID!) {
    markTaskAsComplete(taskId: $taskId) {
      id
      status
    }
  }
`;
//create task
export const CREATE_TASK = gql`
  mutation CreateTask($input: TaskInput) {
    createTask(input: $input) {
      id
    }
  }
`;
//update task
export const UPDATE_TASK = gql`
  mutation UpdateTask($taskId: ID!, $input: TaskInput!) {
    updateTask(task_id: $taskId, input: $input) {
      id
    }
  }
`;
//get  task
export const GET_TASK = gql`
  query Tasks($limit: Int!, $offset: Int!, $filter: taskFilter) {
    tasks(limit: $limit, offset: $offset, filter: $filter) {
      count
      data {
        id
        task_name
        status
        project {
          project_name
        }
        creator {
          first_name
          last_name
        }
        completed_date
        completed_date
        assigned_date
        assigned {
          first_name
          last_name
        }
        test_date
        testor {
          first_name
          last_name
        }
      }
    }
  }
`;
//projects-list
//complete project
export const COMPLETE_PROJECT = gql`
  mutation CompleteProject($projectId: ID!) {
    completeProject(projectId: $projectId) {
      id
      status
    }
  }
`;
//Get projects
export const PROJECT_QUERY = gql`
  query Projects($limit: Int!, $offset: Int!, $filter: projectFilter) {
    projects(limit: $limit, offset: $offset, filter: $filter) {
      count
      data {
        id
        project_name
        description
        flow_chart
        department {
          department_name
          id
        }
        creator {
          id
          first_name
          last_name
        }
        status
        timeline
        confluence
        start_date
        end_date
        sla
        motivation
        project_lead {
          first_name
          last_name
        }
        approvals {
          role {
            group_id
            group_name
          }
          status
        }
        createdAt
        updatedAt
      }
    }
  }
`;

// Create Projects

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($input: ProjectInput) {
    createProject(input: $input) {
      id
      project_name
      description
      flow_chart
      status
      timeline
      confluence
      start_date
      end_date
      sla
      motivation
    }
  }
`;
// Update Projects
export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject($projectId: ID!, $input: ProjectInput) {
    updateProject(projectId: $projectId, input: $input) {
      id
      project_name
      description
      status
      timeline
      confluence
      start_date
      end_date
      sla
      motivation
    }
  }
`;

// Delete Projects
export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      id
    }
  }
`;

//UserRoles
//Get UserRoles
export const USER_USERGROUP_QUERY = gql`
  query UserUserGroups {
    userUserGroups {
      id
      user_id {
        email
        first_name
        last_name
      }
      group_id {
        group_id
        group_name
      }
    }
  }
`;

//user groyup

export const USERGROUP_QUERY = gql`
  query UserGroups($limit: Int, $offset: Int) {
    userGroups(limit: $limit, offset: $offset) {
      group_id
      group_name
    }
  }
`;

//Approve Project
export const APPROVE_PROJECT_MUTATION = gql`
  mutation ApproveProject($projectId: ID!) {
    approveProject(projectId: $projectId) {
      id
    }
  }
`;
//Reject Project
export const REJECT_PROJECT_MUTATION = gql`
  mutation DisapproveProject($projectId: ID!, $input: ProjectDisaprovalInput) {
    disapproveProject(projectId: $projectId, input: $input) {
      id
      motivation
      confluence
    }
  }
`;

//Get Project
export const PROJECT_BY_ID_QUERY = gql`
  query Project($projectId: ID!) {
    project(projectId: $projectId) {
      approvals {
        role {
          group_id
          group_name
        }
        disapproval_reason
        status
      }
      timeline
      status
      project_name
      sla
      start_date
      project_lead {
        id
        last_name
        first_name
      }
      motivation
      flow_chart
      id
      end_date
      description
      department {
        id
        department_name
      }
      creator {
        first_name
        last_name
      }
      confluence
      updatedAt
    }
  }
`;
//tickets
//Create Ticket
export const CREATE_TICKET = gql`
  mutation CreateTicket($input: TicketInput) {
    createTicket(input: $input) {
      id
    }
  }
`;
//ALL_TICKET
export const ALL_TICKETS_QUERY = gql`
  query Tickets($filter: ticketFilter, $limit: Int!, $offset: Int!) {
    tickets(filter: $filter, limit: $limit, offset: $offset) {
      count
      data {
        snaps
        id
        ticket_number
        ticket_description
        ticket_status {
          status_name
        }
        title
        snaps
        priority {
          priority_name
        }
        creator {
          first_name
          last_name
        }
        assigned {
          first_name
          last_name
          id
        }
        assigned_date
        department {
          department_name
        }
        start_date
        end_date
        category {
          category_name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
//assign self ticket
export const ASSIGN_SELF_TICKET = gql`
  mutation AssignMyselfTicket($ticketId: ID!) {
    assignMyselfTicket(ticketId: $ticketId) {
      id
      ticket_number
    }
  }
`;
//get ticket
export const GET_TICKET = gql`
  query Ticket($ticketId: ID!) {
    ticket(id: $ticketId) {
      assigned {
        first_name
        last_name
        id
      }
      category {
        category_name
      }
      creator {
        first_name
        last_name
      }
      priority {
        priority_name
      }
      snaps
      ticket_number
      ticket_description
      ticket_status {
        status_name
      }
      title
      department {
        department_name
      }
      createdAt
      updatedAt
    }
  }
`;
//changeStatus
export const CHANGE_TICKET_STATUS = gql`
  mutation ChangeTicketStatus($statusId: ID!, $changeTicketStatusId: ID!) {
    changeTicketStatus(statusId: $statusId, id: $changeTicketStatusId) {
      ticket_number
      ticket_description
      ticket_status {
        status_name
      }
    }
  }
`;
//DisapproveChangeRequest
export const DISAPPROVE_CHANGE_REQUEST = gql`
  mutation DisapproveChangeRequest(
    $changeRequestId: ID!
    $input: ChangeRequestDisaprovalInput
  ) {
    disapproveChangeRequest(ChangeRequestId: $changeRequestId, input: $input) {
      id
      task_description
    }
  }
`;
//ApproveChangeRequest
export const APPROVE_CHANGE_REQUEST = gql`
  mutation ApproveChangeRequest($changeRequestId: ID!) {
    approveChangeRequest(ChangeRequestId: $changeRequestId) {
      task_description
      id
    }
  }
`;
//getChangeRequests
export const GET_CHANGE_REQUEST = gql`
  query ChangeRequests(
    $limit: Int!
    $offset: Int!
    $filter: changeRequestFilter
  ) {
    changeRequests(limit: $limit, offset: $offset, filter: $filter) {
      count
      data {
        ticket {
          title
          ticket_status {
            status_name
          }
          ticket_number
          ticket_description
          priority {
            priority_name
          }
          creator {
            first_name
            last_name
          }
          assigned {
            last_name
            first_name
          }
          id
        }
        id
        approvals {
          role {
            group_id
            group_name
          }
          id
          disapproval_reason
          status
        }
        task_description
      }
    }
  }
`;
//getChangeRequest
export const GET_A_CHANGE_REQUEST = gql`
  query ChangeRequests($changeRequestId: ID!) {
    changeRequest(id: $changeRequestId) {
      id
      task_description
      ticket {
        id
      }
    }
  }
`;
//LaunchChangeRequest
export const CHANGE_TICKET_REQUEST = gql`
  mutation CreateChangeRequest($input: ChangeRequestInput) {
    createChangeRequest(input: $input) {
      task_description
      ticket {
        ticket_number
      }
      id
    }
  }
`;

//Comment
//get comment
export const GET_A_COMMENT = gql`
  query Comments($limit: Int!, $offset: Int!, $filter: commentFilter) {
    comments(limit: $limit, offset: $offset, filter: $filter) {
      comment_text
      user {
        first_name
        last_name
        initials
        id
      }
      createdAt
    }
  }
`;
//create comment
export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CommentInput) {
    createComment(input: $input) {
      comment_text
      user {
        first_name
        last_name
        initials
      }
    }
  }
`;
//GET PROJECT
export const GET_PROJECT = gql`
  query Projects($limit: Int!, $offset: Int!, $filter: projectFilter) {
    projects(limit: $limit, offset: $offset, filter: $filter) {
      count
      data {
        id
        project_name
        description
        flow_chart
        department {
          department_name
          id
        }
        creator {
          id
          first_name
          last_name
        }
        status
        timeline
        confluence
        start_date
        end_date
        sla
        motivation
        project_lead {
          id
          first_name
          last_name
        }
        approvals {
          role {
            group_id
            group_name
          }
          status
        }
        createdAt
        updatedAt
      }
    }
  }
`;

//Employee
export const ALL_EMPLOYEES_QUERY = gql`
  query Employees($limit: Int, $offset: Int) {
    employees(limit: $limit, offset: $offset) {
      first_name
      last_name
      id
    }
  }
`;
//STATE
export const ALL_STATES_QUERY = gql`
  query States {
    states {
      state_id
      state_name
      abbreviation
      tax_amount
    }
  }
`;
export const ADD_STATE_MUTATION = gql`
  mutation CreateState($input: StateInput) {
    createState(input: $input) {
      abbreviation
      state_name
      tax_amount
    }
  }
`;
export const DELETE_STATE_MUTATION = gql`
  mutation DeleteState($deleteStateId: Int!) {
    deleteState(id: $deleteStateId)
  }
`;
export const EDIT_STATE_MUTATION = gql`
  mutation UpdateState($updateStateId: Int!, $input: StateUpdate) {
    updateState(id: $updateStateId, input: $input) {
      state_id
      state_name
      abbreviation
      tax_amount
    }
  }
`;
//SRT AGENCY
export const ALL_SRT_AGENCY_QUERY = gql`
  query SrtAgencies {
    srtAgencies {
      srt_agency_id
      agency_name
      abbreviation
    }
  }
`;
//SRT
export const CREATE_SRT = gql`
  mutation CreateSrt($input: CreateSrt) {
    createSrt(input: $input) {
      srt_id
      license_plate

      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status

      infration_date
      violation_status
      citation_case_no
    }
  }
`;
export const REASSIGN_SRT = gql`
  mutation ReassignScan($scanId: ID!, $userId: ID!, $reason: String!) {
    reassignScan(scanId: $scanId, userId: $userId, reason: $reason) {
      status
      assigned_id
    }
  }
`;

export const FILTER_BY_SRT_RAW = gql`
  query DistinctDailyScan($limit: Int!, $offset: Int!, $search: scanFilter) {
    distinctDailyScan(limit: $limit, offset: $offset, search: $search) {
      scan_id
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor {
        first_name
        last_name
      }
      validator {
        first_name
        last_name
      }
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount

      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_SRT = gql`
  mutation UpdateSrt($updateSrtId: Int!, $input: SrtInput) {
    updateSrt(id: $updateSrtId, input: $input) {
      srt_id
      license_plate
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
    }
  }
`;
export const UPDATE_SRT_RAW = gql`
  mutation UpdateSrtRaw($input: srtrawupdate) {
    updateSrtRaw(input: $input) {
      srt_raw_id
      license_plate
      lp_state
      trxn_datetime
      exit_lane
      location
      account_id
    }
  }
`;

export const DELETE_SRT_RAW = gql`
  mutation DeleteSrtRaw($deleteSrtRawId: Int!) {
    deleteSrtRaw(id: $deleteSrtRawId)
  }
`;
export const ALL_SRT = gql`
  query Srts($limit: Int!, $offset: Int!, $filterScan: scanFilter) {
    srts(limit: $limit, offset: $offset, filterScan: $filterScan) {
      srt_id
      license_plate

      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unique_id
      transaction_id
      message
      unit_id
      name
      address
      status

      infration_date
      violation_status
      citation_case_no
      agency {
        agency_name
      }
      lp_state {
        state_name
      }
      scan_id {
        scan_id
        scan_doc_file
        scan_doc_name
      }
      client_id {
        organization
        phone
        org_email
      }
    }
  }
`;
export const ASSIGN_SRT = gql`
  mutation UpdateDailyScan($updateDailyScanId: Int!, $input: DailyScanInput) {
    updateDailyScan(id: $updateDailyScanId, input: $input) {
      picked_date
      processor {
        first_name
        last_name
      }
      scan_status
    }
  }
`;
//VALIDATION
export const VALIDATE_SRT = gql`
  mutation UpdateDailyScan($updateDailyScanId: Int!, $input: DailyScanInput) {
    updateDailyScan(id: $updateDailyScanId, input: $input) {
      validator
      scan_status
    }
  }
`;
export const VALIDATION_PDF = gql`
  query Query($dailyScanId: Int!) {
    dailyScan(dailyScanId: $dailyScanId) {
      scan_id
      scan_doc_file
      scan_doc_name
      scan_status
    }
  }
`;
//AGENCY
export const ALL_AGENCYS_QUERY = gql`
  query Agencies {
    agencies {
      agency_id
      agency_name
      abbreviation
      site_url
      state_id {
        state_name
        state_id
        abbreviation
      }
    }
  }
`;
export const ALL_SRT_AGENCYS_QUERY = gql`
  query SrtAgencies {
    srtAgencies {
      srt_agency_id
      agency_name
      abbreviation
    }
  }
`;
export const ADD_AGENCY_MUTATION = gql`
  mutation CreateAgency($input: AgencyInput) {
    createAgency(input: $input) {
      agency_id
      agency_name
      abbreviation
      site_url
      state_id {
        state_name
        state_id
      }
    }
  }
`;
export const DELETE_AGENCY_MUTATION = gql`
  mutation DeleteAgency($deleteAgencyId: Int!) {
    deleteAgency(id: $deleteAgencyId)
  }
`;
export const EDIT_AGENCY_MUTATION = gql`
  mutation UpdateAgency($updateAgencyId: Int!, $input: updateagency) {
    updateAgency(id: $updateAgencyId, input: $input) {
      state_id {
        tax_amount
        state_name
        state_id
        abbreviation
      }
      site_url
      agency_name
      agency_id
      abbreviation
    }
  }
`;

//CATEGORIES
export const ALL_CATEGORIES_QUERY = gql`
  query FileCategories {
    fileCategories {
      file_category_id
      file_category_name
      editable
    }
  }
`;
export const ADD_CATEGORY_MUTATION = gql`
  mutation CreateFileCategory($input: FileCategoryInput) {
    createFileCategory(input: $input) {
      file_category_id
      file_category_name
      editable
    }
  }
`;
export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
export const EDIT_CATEGORY_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
//Notification
export const ALL_NOTIFICATIONS_QUERY = gql`
  query Notifications($filter: NotificationFilter, $offset: Int, $limit: Int) {
    notifications(filter: $filter, offset: $offset, limit: $limit) {
      id
      type
      text
      read
      source_id
      source
      source_identifier
      employee {
        id
        first_name
      }
      createdAt
    }
  }
`;
//MarkNotification
export const MARK_NOTIFICATION = gql`
  mutation MarkNotificationAsRead($markNotificationAsReadId: ID!) {
    markNotificationAsRead(id: $markNotificationAsReadId) {
      id
    }
  }
`;
//MarkAllNotification
export const MARK_ALL_NOTIFICATION = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      id
      text
    }
  }
`;
//User
export const ALL_USER_QUERY = gql`
  query Employees {
    employees {
      first_name
      last_name
      id
      initials
    }
  }
`;
export const FILTER_BY_PROCESSOR = gql`
  query Users($limit: Int!, $offset: Int!, $search: scanFilter) {
    dailyScans(limit: $limit, offset: $offset, search: $search) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status

      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount
      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      processor {
        first_name
        last_name
      }
    }
  }
`;
//DAILY SCANS
export const FILTER_DATE = gql`
  query DailyScans(
    $limit: Int!
    $offset: Int!
    $startDate: String
    $endDate: String
    $search: scanFilter
  ) {
    dailyScans(
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      search: $search
    ) {
      scan_id
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount
      processor {
        first_name
        last_name
      }
      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      createdAt
      updatedAt
      client_id {
        phone
        organization
        org_email
      }
    }
  }
`;
export const ASSIGN_SCAN = gql`
  mutation AssingScan($userId: ID!, $scanId: ID!) {
    assingScan(userId: $userId, scanId: $scanId) {
      status
      assigned_id
      scan_id {
        scan_doc_name
        scan_doc_file
      }
    }
  }
`;
export const SELF_ASSIGN_SCAN = gql`
  mutation SelfassingScan($scanId: ID!) {
    selfassingScan(scanId: $scanId) {
      assigned_id
      status
    }
  }
`;
export const DELETE_DAILY_SCAN = gql`
  mutation DeleteDailyScan($deleteDailyScanId: Int!) {
    deleteDailyScan(id: $deleteDailyScanId)
  }
`;
export const FILTER_BY_STATUS = gql`
  query DailyScans(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
    $startDate: String
    $endDate: String
  ) {
    dailyScans(
      limit: $limit
      offset: $offset
      search: $search
      startDate: $startDate
      endDate: $endDate
    ) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor {
        first_name
        last_name
      }
      validator {
        first_name
        last_name
      }
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount

      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      createdAt
    }
  }
`;
export const ALL_SCANS_QUERY = gql`
  query DailyScans($limit: Int!, $offset: Int!) {
    dailyScans(limit: $limit, offset: $offset) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      user_id {
        user_id
        first_name
      }
      processor {
        first_name
        user_id
      }
      validator {
        user_id
        first_name
      }
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount

      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      createdAt
    }
  }
`;
export const ADD_SCAN_MUTATION = gql`
  mutation CreateFileCategory($input: FileCategoryInput) {
    createFileCategory(input: $input) {
      file_category_id
      file_category_name
      editable
    }
  }
`;
export const DELETE_SCAN_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
export const EDIT_SCAN_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
//MARK VALIDATION
export const MARK_VALIDATION = gql`
  mutation MarkAsValidated($scanId: ID!) {
    markAsValidated(scan_id: $scanId) {
      scan_id
      scanner
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
    }
  }
`;
//DAILY SCANS
export const ALL_USERS_QUERY = gql`
  query DailyScans($limit: Int!, $offset: Int!) {
    dailyScans(limit: $limit, offset: $offset) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor
      validator
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount
      user_id
      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
    }
  }
`;
export const ADD_USER_MUTATION = gql`
  mutation CreateUser($createAcc: createuser) {
    createUser(createAcc: $createAcc) {
      user_id
      first_name
      last_name
    }
  }
`;
export const CURRENT_USER_MUTATION = gql`
  query CurrentUser {
    currentUser {
      user_id
      first_name
      last_name
      username
      email
      phone_number

      role {
        group_id {
          group_name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
export const LOGIN_USER_MUTATION = gql`
  mutation LoginEmployee($username: String!, $password: String!) {
    loginEmployee(username: $username, password: $password) {
      authData {
        token
      }
      employee {
        id
        first_name
        last_name
        phone_number
        title
        address
        initials
      }
      user {
        role {
          id
          group_id {
            group_id
            group_name
          }
        }
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
//SRT RAW
export const SRTRAW_FILTERS = gql`
  query SrtRaws($limit: Int!, $offset: Int!, $filterScan: scanFilter) {
    srtRaws(limit: $limit, offset: $offset, filterScan: $filterScan) {
      srt_raw_id
      license_plate
      lp_state
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency
      infration_date
      citation_case_no
      violation_status
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        scan_id
      }
      message
    }
  }
`;
export const SRT_RAW = gql`
  query DistinctDailyScan($limit: Int!, $offset: Int!) {
    distinctDailyScan(limit: $limit, offset: $offset) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor {
        first_name
        last_name
      }
      validator {
        first_name
        last_name
      }
      picked_date
      processed_date
      srt_doc_file
      srt_status
      srt_lp
      srt_amount
      user_id {
        first_name
        last_name
      }
      pay_by
      pay_date
      paid_srt_doc_file
      paid_srt_date
      scan_type
      createdAt
      updatedAt
    }
  }
`;
export const SRT_FILTER = gql`
  query DistinctDailyScan(
    $limit: Int!
    $offset: Int!
    $filterScan: scanFilter
  ) {
    srtRaws(limit: $limit, offset: $offset, filterScan: $filterScan) {
      srt_raw_id
      license_plate
      lp_state
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency
      infration_date
      citation_case_no
      violation_status
      client_id {
        organization
        phone
        org_email
      }

      message
      scan_id {
        scan_id
      }
    }
  }
`;
export const ALL_SRTRAW = gql`
  query SrtRaws($limit: Int!, $offset: Int!) {
    srtRaws(limit: $limit, offset: $offset) {
      srt_raw_id
      license_plate
      lp_state
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency
      infration_date
      citation_case_no
      violation_status
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        scan_id
        scan_doc_name
      }
    }
  }
`;

export const INSIGHTS = gql`
  query DailyScansTotal($input: dailyscaninsights) {
    dailyScansTotal(input: $input) {
      totalSrts
      totalScan
      totalLps
      invoiceCount
      citationCount
      rentalCount
    }
  }
`;
export const UPDATE_USER = gql`
  mutation Updateuser($updateuserId: Int!, $input: UserInput) {
    updateuser(id: $updateuserId, input: $input) {
      user_id
      first_name
      last_name
      username
      email
      date_of_birth
      gender
      location
      profile_picture
      phone_number
    }
  }
`;

export const SRT_PAYMENT = gql`
  query Srts(
    $filterScan: scanFilter
    $offset: Int!
    $limit: Int!
    $filterSrt: srtFilter
  ) {
    srts(
      filterScan: $filterScan
      offset: $offset
      limit: $limit
      filterSrt: $filterSrt
    ) {
      srt_id
      license_plate
      lp_state {
        state_name
        abbreviation
      }
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency {
        agency_name
      }
      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        scan_doc_name
      }
    }
  }
`;
export const ACCOUNT_AGENCY = gql`
  query AccountAgencies {
    accountAgencies {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const PAYMENT_AGENCY = gql`
  query PaymentAgencies {
    paymentAgencies {
      payment_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;
export const AGENCY_ACCOUNT = gql`
  query AgencyAccounts {
    agencyAccounts {
      account_id
      account_name
      username
      password
      agency_id {
        agency_id
        agency_name
        abbreviation
      }
    }
  }
`;

export const UPDATE_SRTAGENCY_MUTATION = gql`
  mutation UpdateSRTAgency($updateSrtAgencyId: Int!, $input: SRTAgencyInput) {
    updateSRTAgency(id: $updateSrtAgencyId, input: $input) {
      srt_agency_id
      agency_name
      abbreviation
      agency_id {
        agency_id
      }
    }
  }
`;

export const CREATE_SRTAGENCY_MUTATION = gql`
  mutation CreateSRTAgency(
    $agencyId: ID
    $abbreviation: String!
    $agencyName: String!
  ) {
    createSRTAgency(
      agency_id: $agencyId
      abbreviation: $abbreviation
      agency_name: $agencyName
    ) {
      srt_agency_id
      agency_name
      abbreviation
      createdAt
    }
  }
`;

export const DELETE_SRTAGENCY_MUTATION = gql`
  mutation DeleteSRTAgency($deleteSrtAgencyId: Int!) {
    deleteSRTAgency(id: $deleteSrtAgencyId)
  }
`;

export const UPDATE_ACCOUNTAGENCY_MUTATION = gql`
  mutation UpdateAccountAgency(
    $updateAccountAgencyId: Int!
    $input: AccountAgencyInput
  ) {
    updateAccountAgency(id: $updateAccountAgencyId, input: $input) {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const CREATE_ACCOUNTAGENCY_MUTATION = gql`
  mutation CreateAccountAgency($input: AccountAgencyInput) {
    createAccountAgency(input: $input) {
      account_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const DELETE_ACCOUNTAGENCY_MUTATION = gql`
  mutation DeleteAccountAgency($deleteAccountAgencyId: Int!) {
    deleteAccountAgency(id: $deleteAccountAgencyId)
  }
`;

export const UPDATE_PAYMENTAGENCY_MUTATION = gql`
  mutation UpdatePaymentAgency(
    $updatePaymentAgencyId: Int!
    $input: PaymentAgencyInput
  ) {
    updatePaymentAgency(id: $updatePaymentAgencyId, input: $input) {
      payment_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const CREATE_PAYMENTAGENCY_MUTATION = gql`
  mutation CreatePaymentAgency($input: PaymentAgencyInput) {
    createPaymentAgency(input: $input) {
      payment_agency_id
      agency_name
      abbreviation
      site_url
    }
  }
`;

export const DELETE_PAYMENTAGENCY_MUTATION = gql`
  mutation DeletePaymentAgency($deletePaymentAgencyId: Int!) {
    deletePaymentAgency(id: $deletePaymentAgencyId)
  }
`;

export const UPDATE_AGENCYACCOUNT_MUTATION = gql`
  mutation UpdateAgencyAccount(
    $updateAgencyAccountId: Int!
    $input: AgencyAccountInput
  ) {
    updateAgencyAccount(id: $updateAgencyAccountId, input: $input) {
      account_id
      account_name
      username
      password
    }
  }
`;

export const CREATE_AGENCYACCOUNT_MUTATION = gql`
  mutation CreateAgencyAccount($input: AgencyAccountInput) {
    createAgencyAccount(input: $input) {
      account_id
      account_name
      username
      password
    }
  }
`;

export const DELETE_AGENCYACCOUNT_MUTATION = gql`
  mutation DeleteAgencyAccount($deleteAgencyAccountId: Int!) {
    deleteAgencyAccount(id: $deleteAgencyAccountId)
  }
`;

export const UPDATE_AGENCYCOVERAGE_MUTATION = gql`
  mutation UpdateAccountAgencyCoverage(
    $updateAccountAgencyCoverageId: Int!
    $input: AccountAgencyCoverageInput
  ) {
    updateAccountAgencyCoverage(
      id: $updateAccountAgencyCoverageId
      input: $input
    ) {
      account_agency_coverage_id
      account_agency_id
    }
  }
`;

export const CREATE_AGENCYCOVERAGE_MUTATION = gql`
  mutation CreateAccountAgencyCoverage($input: AccountAgencyCoverageInput) {
    createAccountAgencyCoverage(input: $input) {
      account_agency_coverage_id
      account_agency_id
    }
  }
`;

export const DELETE_AGENCYCOVERAGE_MUTATION = gql`
  mutation DeleteAccountAgencyCoverage($deleteAccountAgencyCoverageId: Int!) {
    deleteAccountAgencyCoverage(id: $deleteAccountAgencyCoverageId)
  }
`;

//AGENCY
export const ALL_ACOOUNTCOVERAGE_QUERY = gql`
  query AccountAgencyCoverages {
    accountAgencyCoverages {
      account_agency_coverage_id
      account_agency_id
      agency_id {
        agency_name
        abbreviation
        site_url
        state_id {
          state_name
          abbreviation
          tax_amount
        }
      }
    }
  }
`;

export const ALL_STEPS_QUERY = gql`
  query PaymentSteps {
    paymentSteps {
      step_id
      step_description
    }
  }
`;

export const ALL_CLIENT_FILES_QUERY = gql`
  query ClientFilesServerSide(
    $fileFilter: fileFilterServerSide
    $limit: Int!
    $offset: Int!
  ) {
    clientFilesServerSide(
      fileFilter: $fileFilter
      limit: $limit
      offset: $offset
    ) {
      filteredCount
      count
      data {
        file_id
        client_id {
          id
          organization
        }
        original_name
        asset_file_name
        category_id {
          file_category_id
          file_category_name
        }
        upload_month
        upload_datetime
        createdAt
      }
    }
  }
`;
export const ALL_PAYMENT_VERIFICATION_QUERY = gql`
  query PaymentSrtVerification(
    $offset: Int!
    $limit: Int!
    $filterSrt: paymentSrtFilter
    $filterScan: scanFilter
  ) {
    paymentSrtVerification(
      offset: $offset
      limit: $limit
      filterSrt: $filterSrt
      filterScan: $filterScan
    ) {
      srt_id
      license_plate
      lp_state {
        state_name
      }
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency {
        agency_name
      }
      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        scan_id
        srt_doc_file
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        scan_type
      }
      validatedAt
      amount_site
      total_paid
      collection_amount
      date_paid
      name_on_invoice
      card_number
      notes
      auth_code

      payment_status
    }
  }
`;
export const FILTER_PAYMENT_VERIFICATION_QUERY = gql`
  query PaymentSrtVerification(
    $offset: Int!
    $limit: Int!
    $filterSrt: srtFilter
  ) {
    paymentSrtVerification(
      offset: $offset
      limit: $limit
      filterSrt: $filterSrt
    ) {
      srt_id
      license_plate
      lp_state {
        state_name
      }
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency {
        agency_name
      }
      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        srt_doc_file
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        scan_type
      }
      validatedAt
      amount_site
      total_paid
      collection_amount
      date_paid
      name_on_invoice
      card_number
      notes
      auth_code

      payment_status
    }
  }
`;
export const FILTER_CLIENTFILES_QUERY = gql`
  query ClientFiles($limit: Int!, $offset: Int!, $fileFilter: fileFilter) {
    clientFiles(limit: $limit, offset: $offset, fileFilter: $fileFilter) {
      file_id
      client_id {
        organization
        phone
        org_email
      }
      original_name
      asset_file_name
      upload_month
      upload_datetime
      status
      createdAt
      updatedAt
      category_id {
        file_category_id
        file_category_name
        editable
      }
    }
  }
`;

export const UPDATE_SRT_PAYMENT = gql`
  mutation UpdatePaymentSrts($srtId: ID!, $input: updatepaymentsrts) {
    updatePaymentSrts(srt_id: $srtId, input: $input) {
      srt_id
      license_plate
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      payment_status
    }
  }
`;

export const ALL_USER_INSIGHTS = gql`
  query AllUsersInsights {
    allUsersInsights {
      totalInvoiceCount
      weekNumber
      user {
        first_name
        last_name
      }
      scanCount
    }
  }
`;

export const DOMANT_AGENCIES = gql`
  query DomartSrtAgencies {
    domartSrtAgencies {
      abbreviation
      agency_name
      srt_agency_id
    }
  }
`;

export const SRT_PRIORITY = gql`
  query SrtsPriority($limit: Int!, $offset: Int!) {
    srtsPriority(limit: $limit, offset: $offset) {
      count
      data {
        srt_id
        amount_due
        due_date
        license_plate
        trxn_datetime
        agency {
          agency_name
        }
        lp_state {
          state_name
        }
        scan_id {
          scan_id
        }
      }
    }
  }
`;

export const AGENCY_PERSRTCOUNT = gql`
  query AgencyPerSrtCount {
    agencyPerSrtCount {
      month
      srtAgency {
        srt_agency_id
        agency_name
        abbreviation
        agency_id {
          agency_name
        }
        countofSrt
        totalAmount
      }
    }
  }
`;

export const SRT_IDENTIFY = gql`
  query Srt($srtId: Int!) {
    srt(srtId: $srtId) {
      srt_id
      license_plate
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unique_id
      transaction_id
      message
      unit_id
      name
      address
      status
      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        scan_id
        scan_doc_name
        scan_status
        srt_status
      }
    }
  }
`;

export const ONEPAYMENT_SRT = gql`
  query OnePaymentSrtVerification($srtId: ID!) {
    onePaymentSrtVerification(srt_id: $srtId) {
      srt_id
      license_plate
      lp_state {
        state_name
      }
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      status
      agency {
        agency_name
      }
      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      scan_id {
        srt_doc_file
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        scan_type
      }
      validatedAt
      amount_site
      total_paid
      collection_amount
      date_paid
      name_on_invoice
      card_number
      notes
      auth_code

      payment_status
    }
  }
`;

export const MARKASPAID = gql`
  mutation MarkAsPaid($srtId: ID!) {
    markAsPaid(srt_id: $srtId) {
      srt_id
      license_plate
      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unit_id
      name
      address
      payment_status
    }
  }
`;

export const DASHBOARD_INSIGHTS = gql`
  query SrtsPriority($limit: Int!, $offset: Int!) {
    srtsPriority(limit: $limit, offset: $offset) {
      count
      data {
        srt_id
        license_plate
        trxn_datetime
        amount_due
        due_date
        agency {
          agency_name
        }
        lp_state {
          state_name
        }
        scan_id {
          scan_id
        }
      }
    }

    domartSrtAgencies {
      abbreviation
      agency_name
      srt_agency_id
    }
  }
`;
export const DASHBOARD_INSIGHTS2 = gql`
  query ScanPriorityServerSide(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
  ) {
    scanPriorityServerSide(limit: $limit, offset: $offset, search: $search) {
      count
      filteredCount
      data {
        scan_id
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        srt_status
        processor {
          first_name
          last_name
        }
        validator {
          first_name
          last_name
        }
        picked_date
        processed_date
        scan_type
        createdAt
      }
    }
  }
`;

//SRT VALIDATION BY UNIQUE ID
export const EDIT_SRT_VALIDATION = gql`
  mutation UpdateSrtUnique($updateSrtUniqueId: Int!, $input: SrtInput) {
    updateSrtUnique(id: $updateSrtUniqueId, input: $input) {
      account_id
      address

      citation_case_no
      amount_due
    }
  }
`;
export const SRT_VALIDATED = gql`
  query SrtsuniqueidValidate(
    $limit: Int!
    $offset: Int!
    $filterScan: scanFilter
  ) {
    srtsValidated(limit: $limit, offset: $offset, filterScan: $filterScan) {
      srt_id
      license_plate

      trxn_datetime
      exit_lane
      location
      account_id
      reference_id
      invoice_id
      violation_id
      amount_due
      due_date
      pin_no
      scan_date
      unique_id
      transaction_id
      message
      unit_id
      name
      address
      status

      infration_date
      violation_status
      citation_case_no
      client_id {
        organization
        phone
        org_email
      }
      agency {
        agency_name
        agency_id {
          agency_id
        }
      }
      lp_state {
        state_name
        state_id
      }
      scan_id {
        scan_id
      }
    }
  }
`;

export const DELAYED_SCANS = gql`
  query TurnOverAssignedScans($limit: Int, $offset: Int) {
    turnOverAssignedScans(limit: $limit, offset: $offset) {
      reason
      status
      scan_id {
        scan_id
        scan_doc_file
        scan_doc_name
        client_id {
          org_email
          id
          organization
        }
        createdAt
        scan_type
        no_invoices
        processor {
          first_name
          last_name
        }
        picked_date
      }
      createdAt
      assigned_by {
        first_name
      }
      user_id {
        first_name
      }
      assigned_id
    }
  }
`;

export const ONE_SCAN = gql`
  query DailyScan($dailyScanId: Int!) {
    dailyScan(dailyScanId: $dailyScanId) {
      scan_id
      client_id {
        organization
        phone
        org_email
      }
      scanner
      agency_invoice_status
      scan_doc_file
      scan_doc_name
      no_invoices
      scan_status
      processor {
        first_name
        last_name
        user_id
      }
      picked_date
      processed_date
      user_id {
        user_id
        first_name
        last_name
      }
      scan_type
    }
  }
`;

export const SRT_SERVER_QUERY = gql`
  query SrtsServerSide(
    $limit: Int!
    $offset: Int!
    $filterScan: scanFilter
    $filterSrt: srtFilter
  ) {
    srtsServerSide(
      limit: $limit
      offset: $offset
      filterScan: $filterScan
      filterSrt: $filterSrt
    ) {
      count
      filteredCount
      data {
        srt_id
        license_plate
        lp_state {
          state_name
          abbreviation
        }
        trxn_datetime
        exit_lane
        location
        amount_due
        due_date
        scan_date
        scan_id {
          scan_id
        }
      }
    }
  }
`;

export const SRTVALIDATION_SERVER_QUERY = gql`
  query SrtsValidatedServerSide(
    $limit: Int!
    $offset: Int!
    $filterSrt: srtFilter
    $filterScan: scanFilter
  ) {
    srtsValidatedServerSide(
      limit: $limit
      offset: $offset
      filterSrt: $filterSrt
      filterScan: $filterScan
    ) {
      count
      filteredCount
      data {
        srt_id
        license_plate
        lp_state {
          state_id
          state_name
          abbreviation
        }
        trxn_datetime
        reference_id
        agency {
          srt_agency_id
          agency_name
          abbreviation
        }
        exit_lane
        location
        amount_due
        due_date
        scan_date
        scan_id {
          scan_id
        }
        code_two
        code_one
        bill_no
        pin_no
        violation_id
        invoice_id
        account_id
        citation_no
        password
        case_no
        notice_no
      }
    }
  }
`;

export const SRTUNIQUEVALIDATION_SERVER_QUERY = gql`
  query SrtsUniqueIdValidateServerSide(
    $filterScan: scanFilter
    $filterSrt: srtFilter
    $limit: Int!
    $offset: Int!
  ) {
    srtsUniqueIdValidateServerSide(
      filterScan: $filterScan
      filterSrt: $filterSrt
      limit: $limit
      offset: $offset
    ) {
      count
      filteredCount
      data {
        srt_id
        license_plate
        lp_state {
          state_id
          state_name
          abbreviation
        }
        trxn_datetime
        exit_lane
        location
        amount_due
        due_date
        scan_date
        scan_id {
          scan_id
          client_id {
            organization
          }
          scan_doc_file
          scan_doc_name
          agency_invoice_status
          scan_status
          no_invoices
          scan_type
          createdAt
        }
        message
        agency {
          srt_agency_id
          agency_name
          abbreviation
        }
        code_two
        code_one
        bill_no
        pin_no
        violation_id
        reference_id
        invoice_id
        account_id
        citation_no
        password
        case_no
        notice_no
        page_no
      }
    }
  }
`;

export const SRTRAW_SERVER_QUERY = gql`
  query SrtRawsServerSide(
    $limit: Int!
    $offset: Int!
    $filterScan: scanFilter
    $searchParams: String
    $orderBy: [[String]]
  ) {
    srtRawsServerSide(
      limit: $limit
      offset: $offset
      filterScan: $filterScan
      searchParams: $searchParams
      orderBy: $orderBy
    ) {
      count
      filteredCount
      data {
        srt_raw_id
        license_plate
        lp_state
        trxn_datetime
        exit_lane
        location
        amount_due
        due_date
        agency
        client_id {
          organization
          phone
          org_email
        }
        violation_id
        reference_id
        account_id
        scan_id {
          scan_id
          scan_doc_name
          scan_doc_file
        }
        scan_date
        message
        pin_no
        bill_no
        code_two
        code_one
        invoice_id
        citation_no
        password
        case_no
        notice_no
        page_no
        posted_date
      }
    }
  }
`;

export const DAILYSCAN_SERVER_QUERY = gql`
  query DailyScansServerSide(
    $limit: Int!
    $offset: Int!
    $startDate: String
    $endDate: String
    $searchParams: String
    $search: scanFilter
    $day: String
    $orderBy: [[String]]
  ) {
    dailyScansServerSide(
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      searchParams: $searchParams
      search: $search
      day: $day
      orderBy: $orderBy
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          id
          organization
          phone
          org_email
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status

        validator {
          id
          first_name
          last_name
        }
        picked_date
        processed_date
        scan_type
        srt_amount
        createdAt
        updatedAt
        processor {
          first_name
          last_name
        }
      }
    }
  }
`;

export const DISTINCT_DAILYSCAN_SERVER_QUERY = gql`
  query DistinctDailyScanServerSide(
    $search: scanFilter
    $startDate: String
    $endDate: String
    $limit: Int!
    $offset: Int!
  ) {
    distinctDailyScanServerSide(
      search: $search
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      count
      filteredCount
      data {
        scan_id
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        processor {
          first_name
          last_name
          id
        }
        validator {
          first_name
          last_name
          id
        }
        picked_date
        processed_date
        createdAt
        scan_type
        client_id {
          org_email
          address
          organization
        }
      }
    }
  }
`;
export const SRT_PRIORITY_SERVER_QUERY = gql`
  query ScanPriorityServerSide(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
  ) {
    scanPriorityServerSide(limit: $limit, offset: $offset, search: $search) {
      count
      filteredCount
      data {
        scan_id
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        processor {
          first_name
          last_name
        }
        validator {
          first_name
          last_name
        }
        picked_date
        processed_date
        scan_type
        createdAt
      }
    }
  }
`;

export const PAYSRTVERIFY_SERVER_QUERY = gql`
  query PaymentSrtVerificationServerSide(
    $offset: Int!
    $limit: Int!
    $filterScan: scanFilter
    $filterSrt: paymentSrtFilter
  ) {
    paymentSrtVerificationServerSide(
      offset: $offset
      limit: $limit
      filterScan: $filterScan
      filterSrt: $filterSrt
    ) {
      count
      filteredCount
      data {
        srt_id
        license_plate
        lp_state {
          state_id
          state_name
          abbreviation
        }
        trxn_datetime
        exit_lane
        location
        amount_due
        reference_id
        account_id
        violation_id
        pin_no
        due_date
        agency {
          agency_name
          srt_agency_id
          abbreviation
        }
        scan_id {
          scan_id
          scan_doc_name
          scan_doc_file
        }
        amount_site
        total_paid
        collection_amount
        date_paid
        payment_status
        scan_date
        bill_no
        code_one
        code_two
        invoice_id
        citation_no
        password
        case_no
        notice_no
      }
    }
  }
`;

export const ALL_CLIENTS_QUERY = gql`
  query Clients($filter: clientFilter, $offset: Int, $limit: Int) {
    clients(filter: $filter, offset: $offset, limit: $limit) {
      id
      address
      mail_address
      postal_code
      phone
      org_email
      sec_email
      organization
      po_box
      client_status
    }
  }
`;

export const ADD_CLIENT_MUTATION = gql`
  mutation AddClient($input: ClientsInput) {
    addClient(input: $input) {
      id
      address
      phone
      org_email
      organization
    }
  }
`;

export const NEWSCAN_QUERY = gql`
  query NewDailyScans(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
    $searchParams: String
  ) {
    newDailyScans(
      limit: $limit
      offset: $offset
      search: $search
      searchParams: $searchParams
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          id
          org_email
          organization
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        srt_status
        scan_type
        createdAt
        processor {
          first_name
          last_name
        }
      }
    }
  }
`;

export const ASSIGN_CLIENT_MUTATION = gql`
  mutation AssignClientToEmployee($employeeId: ID!, $clientId: ID!) {
    assignClientToEmployee(employeeId: $employeeId, clientId: $clientId) {
      id
    }
  }
`;
export const ASSIGN_CLIENTS_MUTATION = gql`
  mutation AssignClientsToEmployees($employeeId: ID!, $clientIds: [ID]!) {
    assignClientsToEmployees(employeeId: $employeeId, clientIds: $clientIds) {
      id
    }
  }
`;

export const MULTIPLE_ASSIGN_MUTATION = gql`
  mutation MultipleAssignScans($userId: ID!, $scanIds: [ID]!) {
    multipleAssignScans(userId: $userId, scanIds: $scanIds) {
      assigned_id
    }
  }
`;

export const ASSIGNED_SCANS_QUERY = gql`
  query AssignedScansServerSide(
    $filter: assignScanFilter
    $offset: Int!
    $limit: Int!
  ) {
    assignedScansServerSide(filter: $filter, offset: $offset, limit: $limit) {
      count
      filteredCount
      data {
        scan_id {
          scan_id
          client_id {
            organization
            phone
            org_email
            id
          }
          scanner
          agency_invoice_status
          scan_doc_file
          scan_doc_name
          no_invoices
          scan_status
          processor {
            id
            first_name
            last_name
          }
          validator {
            id
            first_name
            last_name
          }
          picked_date
          processed_date
          srt_amount
          createdAt
        }
        status
      }
    }
  }
`;

export const NEW_ASSIGNED_SCANS_QUERY = gql`
  query NewAssignedDailyScans(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
    $searchParams: String
  ) {
    newAssignedDailyScans(
      limit: $limit
      offset: $offset
      search: $search
      searchParams: $searchParams
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          organization
          phone
          org_email
          id
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        processor {
          id
          first_name
          last_name
        }
        validator {
          id
          first_name
          last_name
        }
        picked_date
        processed_date
        srt_amount
        createdAt
        scan_type
      }
    }
  }
`;
export const UNASSIGN_MUTATION = gql`
  mutation UnassignScan($scanId: ID!, $reason: String!) {
    unassignScan(scanId: $scanId, reason: $reason) {
      assigned_id

      status
      reason
      createdAt
      updatedAt
    }
  }
`;

export const CLIENT_DEPT_QUERY = gql`
  query ClientDepartments($clientId: ID!) {
    clientDepartments(clientId: $clientId) {
      id
      dept_address
      dept_phone
      dept_email
      dept_name
      category
      dept_status
    }
  }
`;

export const REQUEST_RESETPASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      userId
      username
      email
    }
  }
`;

export const RESETPASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $code: String!
    $userId: String!
    $confirmPassword: String!
    $password: String!
  ) {
    resetPassword(
      code: $code
      userId: $userId
      confirmPassword: $confirmPassword
      password: $password
    ) {
      email
      userId
      username
    }
  }
`;

export const ROLLBACK_MUTATION = gql`
  mutation RollBackSrt($scanId: ID!, $reason: String!) {
    rollBackSrt(scan_id: $scanId, reason: $reason) {
      scan_doc_file
      scan_id
    }
  }
`;

export const ASSIGN_VALIDATOR_MUTATION = gql`
  mutation Mutation($employeeId: ID!, $scanId: ID!) {
    assignValidator(employeeId: $employeeId, scanId: $scanId) {
      scan_id
    }
  }
`;

export const SELF_ASSIGN_VALIDATOR_MUTATION = gql`
  mutation Mutation($scanId: ID!) {
    selfAssignValidator(scanId: $scanId) {
      scan_id
    }
  }
`;

export const AWAIT_VALIDATOR_QUERY = gql`
  query AwaitingValidationDailyScans(
    $search: scanFilter
    $limit: Int!
    $offset: Int!
    $searchParams: String
  ) {
    awaitingValidationDailyScans(
      search: $search
      limit: $limit
      offset: $offset
      searchParams: $searchParams
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          organization
          phone
          org_email
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        processor {
          id
          first_name
          last_name
        }
        validator {
          id
          first_name
          last_name
        }
        picked_date
        processed_date
        scan_type
        srt_amount
        createdAt
        updatedAt
      }
    }
  }
`;

export const UNIQUIDWITHOUT_QUERY = gql`
  query ScansWithoutUniqueIds(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
  ) {
    scansWithoutUniqueIds(limit: $limit, offset: $offset, search: $search) {
      count
      filteredCount
      data {
        client_id {
          organization
        }
        scan_doc_file
        scan_doc_name
        createdAt
        no_invoices
        scan_id
        scan_type
      }
    }
  }
`;

export const INSIGHTS_QUERY = gql`
  query TransactionCountPerAgency {
    transactionCountPerAgency {
      labels
      data {
        label
        data
        borderColor
        pointBackgroundColor
        fill
      }
    }
  }
`;
export const MISSING_INFORMATION_QUERY = gql`
  query MissingTransaction(
    $limit: Int!
    $offset: Int!
    $filter: srtFilter
    $searchParams: String
    $orderBy: [[String]]
  ) {
    missingTransaction(
      limit: $limit
      offset: $offset
      filter: $filter
      searchParams: $searchParams
      orderBy: $orderBy
    ) {
      data {
        id
        license_plate
        lp_state {
          state_name
          state_id
          abbreviation
        }
        trxn_datetime
        exit_lane
        location
        account_id
        reference_id
        invoice_id
        violation_id
        amount_due
        due_date
        pin_no
        scan_date
        dept_id {
          id
          client {
            org_email
            organization
          }
          dept_name
          dept_email
        }
        agency {
          abbreviation
          agency_name
        }
        infration_date
        citation_case_no
        violation_status

        scan_id {
          scan_doc_name
          scan_doc_file
          scan_id
        }
        message
      }
      count
      filteredCount
    }
  }
`;

export const DISTINCT_LPS_QUERY = gql`
  query ExampleQuery($search: distinctFilter, $offset: Int, $limit: Int) {
    distinctLicensePlateServerside(
      search: $search
      offset: $offset
      limit: $limit
    ) {
      count
      data {
        license_plate
        lp_state
        agency
        reference_id
        account_id
        invoice_id
        violation_id
        pin_no
      }
      filteredCount
    }
  }
`;

export const ONLINETRANSACTIONSINSIGHTS_QUERY = gql`
  query OnlineTransactionsInsights {
    onlineTransactionsInsights {
      count
      withinSLA
      outsideSLA
      unInvoicedAmount
      withinSlaPercentage
      outsideSlaPercentage
    }
  }
`;
export const SCANNEDTRANSINSIGHTS_QUERY = gql`
  query ScannedInvoiceTransactionsInsights {
    scannedInvoiceTransactionsInsights {
      count
      withinSLA
      outsideSLA
      unInvoicedAmount
      withinSlaPercentage
      outsideSlaPercentage
    }
  }
`;
export const SFTPFileUpload = gql`
  mutation SftpFileUpload($input: SFTPUploadInput!) {
    sftpFileUpload(input: $input)
  }
`;
export const LIST_SFTP_FILE = gql`
  query ListRemoteFiles($folderName: String) {
    listRemoteFiles(folder_name: $folderName) {
      name
      size
      createdAt
    }
  }
`;
export const ARCHIVE_MUTATION = gql`
  mutation ArchiveScan($scanId: ID!, $reason: String!) {
    archiveScan(scan_id: $scanId, reason: $reason) {
      scan_id
      scan_doc_name
      scan_doc_file
      createdAt
    }
  }
`;
export const UNARCHIVE_MUTATION = gql`
  mutation UnArchiveScan($scanId: ID!) {
    unArchiveScan(scan_id: $scanId) {
      scan_id
      scan_doc_name
      scan_doc_file
    }
  }
`;

export const ADMIN_DAILYSCAN_SERVER_QUERY = gql`
  query DailyScansServerSide(
    $limit: Int!
    $offset: Int!
    $startDate: String
    $endDate: String
    $searchParams: String
    $search: scanFilter
    $day: String
    $orderBy: [[String]]
  ) {
    dailyScans(
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      searchParams: $searchParams
      search: $search
      day: $day
      orderBy: $orderBy
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          organization
          phone
          org_email
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status

        validator {
          id
          first_name
          last_name
        }
        picked_date
        processed_date
        scan_type
        srt_amount
        createdAt
        updatedAt
        processor {
          first_name
          last_name
        }
      }
    }
  }
`;

export const SUB_DEPT_QUERY = gql`
  query SubDepartments(
    $offset: Int
    $limit: Int
    $filter: subDepartmentFilter
  ) {
    subDepartments(offset: $offset, limit: $limit, filter: $filter) {
      id
      name
      department {
        dept_name
        id
      }
      email
      phone
      address
      createdAt
      updatedAt
    }
  }
`;

export const EDIT_UNIQUEID_MUTATION = gql`
  mutation Mutation($updateSrtUniqueId: Int!, $input: SrtInput) {
    updateSrtUnique(id: $updateSrtUniqueId, input: $input) {
      srt_id
    }
  }
`;

export const AVAILABLE_SCANS_QUERY = gql`
  query AvailableDailyScans(
    $limit: Int!
    $offset: Int!
    $search: scanFilter
    $searchParams: String
    $orderBy: [[String]]
    $day: String
    $endDate: String
    $startDate: String
  ) {
    availableDailyScans(
      limit: $limit
      offset: $offset
      search: $search
      searchParams: $searchParams
      orderBy: $orderBy
      day: $day
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      filteredCount
      data {
        scan_id
        client_id {
          organization
        }
        scanner
        agency_invoice_status
        scan_doc_file
        scan_doc_name
        no_invoices
        scan_status
        processor {
          first_name
          last_name
          phone_number
        }
        validator {
          first_name
          last_name
          phone_number
        }
        picked_date
        processed_date
        srt_doc_file
        srt_status
        srt_lp
        srt_amount
        scan_type
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_SCANS_QUERY = gql`
  query DailyScanStatus($searchParams: String!) {
    dailyScanStatus(searchParams: $searchParams) {
      count
      data {
        scan_id
        dept_id {
          dept_name
        }
        scan_doc_file
        scan_doc_name
        processor {
          first_name
          last_name
        }
        validator {
          first_name
          last_name
        }
        picked_date
        formattingErrorCount
        srtCount
        duplicateTransactionCount
        missingTransactionCount
        scan_status
        client_id {
          organization
        }
      }
    }
  }
`;

export const DAILY_SCAN_REPORT_QUERY = gql`
  query DailyScanReport(
    $offset: Int!
    $limit: Int!
    $searchParams: String
    $day: String
  ) {
    dailyScanReport(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
      day: $day
    ) {
      count
      data {
        scan_status
        scan_doc_file
        scan_doc_name
        processor {
          last_name
          first_name
        }
        original_name
        client_id {
          organization
        }
        dept_id {
          dept_name
        }
        validator {
          last_name
          first_name
        }
        scan_id
        picked_date
      }
      countOfScans
      countOfScansArchived
      filteredCount
      countOfOnlineScans
      countOfRentals
    }
  }
`;
export const MERGED_SRT_QUERY = gql`
  query MergedSrts($scanId: ID!) {
    mergedSrts(scan_id: $scanId) {
      count
      filteredCount
      data {
        srt_id
        license_plate
        lp_state {
          abbreviation
          state_name
          state_id
        }
        trxn_datetime
        exit_lane
        location
        account_id
        reference_id
        invoice_id
        violation_id
        amount_due
        due_date
        pin_no
        scan_date
        unique_id
        transaction_id
        name
        address
        status
        agency {
          abbreviation
          agency_name
          srt_agency_id
        }
        state
        infration_date
        violation_status
        citation_case_no
        case_no
        notice_no
        citation_no
        password
        client_id {
          id
          org_email
          organization
          phone
        }
        scan_id {
          scan_doc_name
          scan_doc_file
          srt_status
          scan_id
          scan_status
          scan_type
          agency_invoice_status
          client_id {
            id
            organization
            org_email
          }
        }
        code_one
        code_two
        bill_no
        page_no
        source
        createdAt
        posted_date
      }
    }
  }
`;

export const SRT_MERGEUPDATE_MUTATION = gql`
  mutation UpdateMergeSrt(
    $updateMergeSrtId: ID!
    $source: String!
    $input: SrtInput
  ) {
    updateMergeSrt(id: $updateMergeSrtId, source: $source, input: $input) {
      scan_id {
        scan_doc_file
        scan_doc_name
      }
    }
  }
`;

export const DAILY_USEREPORT_QUERY = gql`
  query DailyUserReport($employeeId: ID, $day: String) {
    dailyUserReport(employeeId: $employeeId, day: $day) {
      client_id {
        org_email
        organization
      }
      dept_id {
        dept_email
        dept_name
      }
      processor {
        first_name
        last_name
      }
      validator {
        first_name
        last_name
      }
      countOfLps
      scan_doc_name
      scan_doc_file
      excel_doc_file
      scan_id
    }
  }
`;

export const VALIDATOR_BULK_MUTATION = gql`
  mutation MultipleAssignValidator($employeeId: ID!, $scanIds: [ID]!) {
    multipleAssignValidator(employeeId: $employeeId, scanIds: $scanIds) {
      scan_id
      scan_doc_name
      scan_doc_file
    }
  }
`;

export const SCAN_QUERY = gql`
  query DailyScan($dailyScanId: Int!) {
    dailyScan(dailyScanId: $dailyScanId) {
      scan_doc_file
      scan_doc_name
    }
  }
`;

//Update Project
// export const UPDATE_PROJECT_MUTATION = gql`
//   mutation UpdateProject($projectId: ID!, $input: ProjectInput) {
//     updateProject(projectId: $projectId, input: $input) {
//       id
//       project_name
//       description
//       flow_chart
//       status
//       timeline
//       confluence
//       start_date
//       end_date
//       sla
//       motivation
//     }
//   }
// `;

// //Delete Project  DELETE_PROJECT_MUTATION is not used in this file. It's used in the projects file instead of UPDATE_PROJECT_MUTATION
// //Delete Project
// export const DELETE_PROJECT_MUTATION = gql`
//   mutation DeleteProject($id: ID!) {
//     deleteProject(id: $id) {
//       id
//     }
//   }
// `;
